@import "src/tokens";

.root {
}

.concern {
  color: $dark-alpha-70;
  padding-bottom: $space-m;
  letter-spacing: normal;
}

.link {
  font-weight: $medium;
  border-bottom: 1px solid $brand-primary-100;
}

.alternative {
  padding-top: $space-ml;
  display: flex;
  align-items: baseline;
  column-gap: $space-xs;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
