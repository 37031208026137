@import "src/tokens";

.root {
  &:not(:last-child) {
    margin-bottom: $space-field;
  }
}

.label {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  font-size: $heading-5;
  line-height: 22px;
  color: $dark-alpha-90;
  font-weight: $semibold;
  margin-bottom: $space-s;
}

.primaryLabel {
  display: flex;
  margin-right: auto;
  flex: 1;
}

.fieldWrapper {
  position: relative;
}

.field {
  padding: 11px;
  flex: 1;
  font-size: $heading-5;
  line-height: 24px;
  border-radius: $radius-field;
  display: flex;
  width: 100%;
  outline: none;
  box-sizing: border-box;
  appearance: none;
  color: $dark-alpha-80;
  background-color: $light-alpha-100;

  @include fieldInput();

  &.error {
    border-color: $brand-primary-200;
    background-color: $brand-primary-10;
  }
}

.visibilityWrapper {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  width: 48px;
  height: 48px;
  padding: 12px;
  transition: all 0.28s ease-out;
  color: $dark-hex-50;

  &:hover {
    color: $dark-hex-60;
    transition: all 0.28s ease-out;
  }
}

.icon {
  width: 24px;
  height: 24px;
}

.hintWrapper {
  padding-top: $space-s;
  padding-bottom: $space-s;
}

.hint {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $dark-hex-50;
  padding-bottom: $space-xs;

  &.error {
    color: $tone-danger-200;
  }

  &.success {
    color: $tone-success-200;
  }
}

.hintIcon {
  width: 16px;
  height: 16px;
  margin-right: $space-xs;
}

.hintLabel {
  font-size: 14px;
  line-height: 16px;
  font-weight: $regular;
}

.forgot {
  font-weight: $semibold;
  font-size: 14px;
  line-height: 16px;
  color: $brand-primary-200;
  justify-self: flex-end;
  margin-left: auto;
}
