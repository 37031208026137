@import "src/tokens";

.body {
  font-family: $font-primary;
}

.heading {
  font-family: $font-primary;
}

// Display Text
.display1 {
  composes: heading;
  @include rf($display-2, $display-1);
  line-height: $line-reset;
  letter-spacing: -1.44px;
  font-weight: $extrabold;
}
.display2 {
  composes: heading;
  @include rf($display-3, $display-2);
  line-height: $line-reset;
  letter-spacing: -1.44px;
  font-weight: $extrabold;
}
.display3 {
  composes: heading;
  @include rf($heading-1, $display-3);
  line-height: $line-reset;
  letter-spacing: -1.44px;
  font-weight: $extrabold;
}

// Heading Text
.heading1 {
  composes: heading;
  @include rf($heading-2, $heading-1);
  line-height: 52px;
  letter-spacing: -1.44px;
  font-weight: $bold;
  letter-spacing: -0.024em;
}
.heading2 {
  composes: heading;
  @include rf($heading-3, $heading-2);
  line-height: 36px;
  letter-spacing: -1.2px;
  font-weight: $bold;
  letter-spacing: -0.024em;
}
.heading3 {
  composes: heading;
  @include rf(20px, $heading-3);
  line-height: 30px;
  letter-spacing: -0.48px;
  font-weight: $bold;
  letter-spacing: -0.024em;
}
.heading3Alt {
  composes: heading;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.48px;
  font-weight: $semibold;
  letter-spacing: -0.024em;
}
.heading4 {
  composes: heading;
  font-size: $heading-4;
  line-height: 26px;
  letter-spacing: -0.48px;
  font-weight: $semibold;
  letter-spacing: -0.024em;
}
.heading5 {
  font-size: $heading-5;
  line-height: 22px;
  letter-spacing: -0.24px;
  font-weight: $medium;
  letter-spacing: -0.024em;
}
.heading6 {
  font-size: $heading-6;
  line-height: 18px;
  letter-spacing: -0.24px;
  font-weight: $medium;
  letter-spacing: -0.024em;
}

// Body Text
.large {
  font-size: $body-3;
  line-height: 26px;
  font-weight: $regular;
  letter-spacing: -0.024em;
}
.medium {
  font-size: $body-2;
  line-height: 22px;
  font-weight: $regular;
  letter-spacing: -0.024em;
}
.small {
  font-size: $body-1;
  line-height: 18px;
  font-weight: $regular;
  letter-spacing: -0.024em;
}

// Caption
.caption {
  font-size: $caption-1;
  font-weight: $regular;
  line-height: 16px;
}

// Label
.label {
  font-size: $heading-5;
  line-height: 22px;
  color: $dark-alpha-90;
  font-weight: $semibold;
  margin: $space-stack-s;
}
.labelSmall {
  font-size: $heading-6;
  line-height: 18px;
  color: $dark-alpha-70;
  font-weight: $medium;
  margin: $space-stack-s;
}

.link {
  cursor: pointer;
  color: $brand-primary-200;
  text-decoration: none;
  transition: all 0.32s;

  &:hover {
    transition: all 0.28s;
    text-decoration: underline;
  }
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.justify {
  text-align: justify;
}

.breakWord {
  hyphens: auto;
  word-wrap: break-word;
}

.truncate {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: normal;
}

// .chevron {
//   margin-left: $space-s;
//   animation: chevronTransform 0.4s 1 cubic-bezier(0.8, 0, 0.2, 1);
// }

// @keyframes chevronTransform {
//   0% {
//     margin-left: 0;
//   }
//   100% {
//     margin-left: 4px;
//   }
// }

// .fancyLink {
//   font-family: $font-primary;
//   width: fit-content;
//   color: $brand-primary-200;
//   text-decoration: none;
//   background-image: linear-gradient(180deg, $brand-primary-200 30%, $brand-primary-200 30%);
//   background-size: 0% 1.5px;
//   background-repeat: no-repeat;
//   background-position: 0 100%;
//   transition: all 0.12s;
//   cursor: pointer;

//   &:hover {
//     background-size: 100% 1.5px;
//     text-shadow: textshadow($light-alpha-100);
//     text-decoration: underline;
//     color: $brand-primary-300;
//   }
// }

.prewrap {
  white-space: pre-wrap !important;
}
