@import "src/tokens";

.root {
  align-items: center;
  justify-content: flex-start;
  justify-self: flex-start;

  @include screen(smallPhone) {
    display: none;
  }
  @include screen(phone) {
    display: none;
  }
  @include screen(tablet) {
    display: none;
  }
  @include screen(tabletLandscape) {
    display: inline-flex;
  }
  @include screen(desktop) {
    display: inline-flex;
  }
  @include screen(desktopWide) {
    display: inline-flex;
  }
}
