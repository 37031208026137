@import "src/tokens";

$xxsmall: 16px;
$xsmall: 24px;
$small: 32px;
$medium: 48px;
$large: 64px;
$xlarge: 96px;
$xxlarge: 128px;
$huge: 144px;

.root {
  display: block;
  position: relative;
  overflow: hidden;

  &:not(.square) {
    border-radius: $radius-rounded;
  }

  &.xxsmall {
    width: $xxsmall;
    height: $xxsmall;
    min-width: $xxsmall;
    min-height: $xxsmall;
  }
  &.xsmall {
    width: $xsmall;
    height: $xsmall;
    min-width: $xsmall;
    min-height: $xsmall;
  }
  &.small {
    width: $small;
    height: $small;
    min-width: $small;
    min-height: $small;
  }
  &.medium {
    width: $medium;
    height: $medium;
    min-width: $medium;
    min-height: $medium;
  }
  &.large {
    width: $large;
    height: $large;
    min-width: $large;
    min-height: $large;
  }
  &.xlarge {
    width: $xlarge;
    height: $xlarge;
    min-width: $xlarge;
    min-height: $xlarge;
  }
  &.xxlarge {
    width: $xxlarge;
    height: $xxlarge;
    min-width: $xxlarge;
    min-height: $xxlarge;
  }
  &.huge {
    width: $huge;
    height: $huge;
    min-width: $huge;
    min-height: $huge;
  }
}

.photo {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  object-position: center center;

  &.contain {
    object-fit: contain;
  }
}
