@import "src/tokens";

.root {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.76);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--tenka-drawer);

  @media (max-width: 950px) {
    display: block;
  }

  @media (min-width: 951px) {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: var(--viewport-height);
    overflow-y: auto;
  }
}

.content {
  background-color: $light-hex-100;
  display: flex;
  flex-direction: column;
  box-shadow: 0px -16px 40px -12px rgba(0, 0, 0, 0.33);
  height: auto;

  &:not(.full) {
    min-height: fit-content;
    border-top-right-radius: 24px;
    border-top-left-radius: 24px;
  }

  &.full {
    min-height: var(--viewport-height);
    overflow-y: auto;
  }

  @media (max-width: 950px) {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: var(--viewport-height);
  }

  @media (min-width: 951px) {
    position: static;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: auto;
    max-width: 568px;
    width: 100%;
    border-bottom-right-radius: 24px;
    border-bottom-left-radius: 24px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10vh;
    margin-bottom: 10vh;
  }
}

.handler {
  position: relative;
  display: block;
  padding-top: 6px;
  padding-bottom: 6px;
  outline: none;
  border: none;
  background-color: transparent;

  &::before {
    content: " ";
    width: 20%;
    max-width: 56px;
    height: 4px;
    display: block;
    border-radius: 99999px;
    background-color: rgba(0, 0, 0, 0.27);
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 950px) {
    visibility: visible;
  }

  @media (min-width: 951px) {
    visibility: hidden;
  }
}
