@import "src/tokens";

.root {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: var(--tenka-mobile-menu);

  @include screen(smallPhone) {
    display: block;
  }
  @include screen(phone) {
    display: block;
  }
  @include screen(tablet) {
    display: block;
  }
  @include screen(tabletLandscape) {
    display: none;
  }
  @include screen(desktop) {
    display: none;
  }
  @include screen(desktopWide) {
    display: none;
  }
}

.wrapper {
  display: flex;
  // grid-template-columns: repeat(5, 1fr);
  list-style: none;
  justify-content: center;
  justify-items: center;
  column-gap: var(--tenka-space-s);
  padding-top: var(--tenka-space-s);
  padding-bottom: var(--tenka-space-s);
  background-color: var(--tenka-light-hex-100);
  box-shadow: 0px -8px 12px var(--tenka-dark-10);
  padding-left: var(--tenka-space-m);
  padding-right: var(--tenka-space-m);
}

.menu {
  width: 100%;
  color: $dark-alpha-60;

  &.active {
    color: $brand-primary-300;
  }
}

.link {
  cursor: pointer;
  border: none;
  outline: none;
  width: 100%;
  height: 48px;
  min-width: 56px;
  min-height: 48px;
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 4px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: inherit;

  &:hover {
    background-color: $dark-alpha-10;
    transition: all 0.28s ease-out;
  }
}

.icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-left: auto;
  margin-right: auto;
}

.label {
  display: block;
  font-size: 11px;
  line-height: 12px;
  padding-top: 4px;
}

.menuLink {
  font-size: 15px;
  line-height: 24px;
  display: block;
  padding-top: var(--tenka-space-sm);
  padding-bottom: var(--tenka-space-sm);
  border-bottom: 1px solid var(--tenka-dark-20);
}

.copy {
  padding-top: var(--tenka-space-ml);
  display: block;
  border-top: 1px solid var(--tenka-dark-20);
}

.socialList {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: $space-ml;
}

.social {
  display: block;
  text-decoration: none;
  &:not(:first-child) {
    margin-left: $space-m;
  }

  &:not(:last-child) {
    margin-right: $space-m;
  }
}

.socialIcon {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
}

.banner {
  padding: 20px var(--tenka-space-m);
  border-radius: var(--tenka-radius-m);
  background-color: var(--tenka-dark-20);
  margin-left: -16px;
  margin-right: -16px;
}

.cta {
  padding-top: var(--tenka-space-s);
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}

.chevron {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
}

.user {
  padding-top: var(--tenka-space-ml);
  padding-bottom: var(--tenka-space-xl);
}
