.x {
  &.xxs {
    width: var(--tenka-space-xxs);
  }
  &.xs {
    width: var(--tenka-space-xs);
  }
  &.s {
    width: var(--tenka-space-s);
  }
  &.sm {
    width: var(--tenka-space-sm);
  }
  &.m {
    width: var(--tenka-space-m);
  }
  &.ml {
    width: var(--tenka-space-ml);
  }
  &.l {
    width: var(--tenka-space-l);
  }
  &.xl {
    width: var(--tenka-space-xl);
  }
  &.xxl {
    width: var(--tenka-space-xxl);
  }
  &.xxxl {
    width: var(--tenka-space-xxxl);
  }
}

.y {
  &.xxs {
    height: var(--tenka-space-xxs);
  }
  &.xs {
    height: var(--tenka-space-xs);
  }
  &.s {
    height: var(--tenka-space-s);
  }
  &.sm {
    height: var(--tenka-space-sm);
  }
  &.m {
    height: var(--tenka-space-m);
  }
  &.ml {
    height: var(--tenka-space-ml);
  }
  &.l {
    height: var(--tenka-space-l);
  }
  &.xl {
    height: var(--tenka-space-xl);
  }
  &.xxl {
    height: var(--tenka-space-xxl);
  }
  &.xxxl {
    height: var(--tenka-space-xxxl);
  }
}
