@import "src/tokens";

.root {
  padding-right: $space-ml;
  padding-top: 0;
  padding-bottom: 0;
  display: block;
  white-space: nowrap;

  &:not(:first-child) {
    padding-left: $space-ml;
  }
}

.menu {
  height: 64px;
  padding-top: $space-ml;
  padding-bottom: $space-ml;
  display: block;
  line-height: 16px;
  transition: all 0.36s cubic-bezier(0.8, 0, 0.2, 1);
  color: $dark-alpha-70;

  &.web {
    height: 96px;
    padding-top: 38px;
    padding-bottom: 38px;
  }

  &:hover {
    box-shadow: inset 0px -3px 0px 0px $brand-primary-300;
    transition: all 0.28s cubic-bezier(0.8, 0, 0.2, 1);
  }
}

.active {
  color: $brand-primary-200;
  box-shadow: inset 0px -3px 0px 0px $brand-primary-300;
}

.item {
  display: inline-flex;
  list-style: none;
  line-height: 16px !important;
  margin: 0;
  align-items: center;
  justify-content: flex-start;
}
