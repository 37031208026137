@import "src/tokens";

.root {
}

.user {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: $space-xs;
  padding-bottom: $space-xs;
  border-radius: 6px;
  transition: all 0.4s ease-in-out;
}

.avatar {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  border-radius: $radius-rounded;
  margin-right: $space-s;
}

.chevron {
  transform: rotate(-90deg);
  display: block;
  width: 20px;
  height: 20px;
  color: $dark-hex-70;
}

.area {
  max-width: 288px;
  min-width: 256px;
  background-color: $bg-primary;
  padding: $space-s $space-m;
  // border: 1px solid $dark-alpha-30;
  margin-top: 4px;
  border-radius: 8px;
  box-shadow: 0px 2px 12px 2px $dark-alpha-20, 0px -2px 8px -2px $dark-alpha-20;
}

.divider {
  margin-top: $space-s;
  margin-bottom: $space-s;
  width: 100%;
  height: 1px;
  background-color: $dark-alpha-20;
}

.menuWrapper {
  padding-top: 10px;
  padding-bottom: 10px;
}

.menu {
  cursor: pointer;
  font-size: 15px;
  line-height: 24px;
  font-weight: $medium;
  color: $dark-hex-70;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.label {
  display: block;
  flex: 1;
}

.icon {
  width: 24px;
  height: 24px;
  color: $dark-hex-60;
}
