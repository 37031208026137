@import "src/tokens";

.root {
  min-width: 48px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.brandContainer {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.wrapperNavbar {
  padding-top: $space-s;
  padding-bottom: $space-s;
}

.brand {
  height: 48px;
  // color: $dark-hex-70;
}

.brandCustom {
  display: block;
}

.brandWordmark {
  height: 48px;
  transform: translateY(4px);
  // color: $dark-hex-70;
}
