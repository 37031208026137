/*
  Line Height tokens
*/

// Use for heading text and UI text
$line-compact: 1.25;
$line-cozy: 1.125;

// Use for long-form text and paragraph text
$line-comfortable: 1.5;

// Use for oversized heading text
$line-reset: 1;

