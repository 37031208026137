/*
  TextSize tokens
*/
// Marketing --- Use only for a component which really need to attract some attentions
$display-1: 128px;
$display-2: 96px;
$display-3: 64px;

// Product
$heading-1: 46px;
$heading-2: 32px;
$heading-3: 24px;
$heading-4: 18px;
$heading-5: 16px;
$heading-6: 14px;

$body-1: 14px;
$body-2: 16px;
$body-3: 18px;

$caption-1: 13px;
$caption-2: 14px;
