@import "src/tokens";

.root {
  padding-top: $space-m;
  padding-bottom: $space-m;
  border-radius: 8px;
  transition: all 0.36s ease-out;
  width: calc(100% + 16px);
  margin-left: -8px;
  margin-right: -8px;
  padding-left: 8px;
  padding-right: 8px;

  &:hover {
    background-color: $dark-alpha-10;
    transition: all 0.28s ease-out;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: $space-m;
  width: 100%;
  max-width: 100%;
}

.photo {
  width: fit-content;
}

.aside {
  width: fit-content;
}

.main {
  flex: 1;
}

.text {
  &:not(.unread) {
    color: $dark-alpha-60;
  }

  &.unread {
    color: $dark-alpha-80;
  }
}

.timestamp {
  color: $brand-primary-200;
}

.marker {
  width: 12px;
  height: 12px;
  border-radius: $radius-rounded;
  background-color: $brand-primary-300;
}

.iconWrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  min-width: 48px;
  height: 48px;
  background-color: $dark-alpha-05;
  border-radius: $radius-rounded;
}

.icon {
  width: 28px;
  height: 28px;

  &.rejected {
    color: $tone-danger-200;
  }

  &.verified {
    color: $tone-success-200;
  }
}
