@import "src/tokens";

.root {
}

.contact {
  cursor: pointer;
  z-index: $layer-top;
  text-align: left;
  position: fixed;
  color: $light-hex-100;
  background-color: $brand-primary-300;
  border-radius: $radius-rounded;
  box-shadow: 0px 8px 16px -4px $dark-alpha-40;
  display: flex;
  align-items: center;
  right: 16px;

  @include screen(smallPhone) {
    font-size: 14px;
    line-height: 20px;
    font-weight: var(--tenka-weight-500);
    padding: 8px 16px 8px 8px;
    bottom: 80px;
  }
  @include screen(phone) {
    font-size: 14px;
    line-height: 20px;
    font-weight: var(--tenka-weight-500);
    padding: 8px 16px 8px 8px;
    bottom: 80px;
  }
  @include screen(tablet) {
    font-size: 14px;
    line-height: 20px;
    font-weight: var(--tenka-weight-500);
    padding: 8px 16px 8px 8px;
    bottom: 80px;
  }
  @include screen(tabletLandscape) {
    font-size: 17px;
    line-height: 26px;
    font-weight: var(--tenka-weight-600);
    padding: 8px 24px 8px 15px;
    bottom: 16px;
  }
  @include screen(desktop) {
    font-size: 17px;
    line-height: 26px;
    font-weight: var(--tenka-weight-600);
    padding: 8px 24px 8px 15px;
    bottom: 16px;
  }
  @include screen(desktopWide) {
    font-size: 17px;
    line-height: 26px;
    font-weight: var(--tenka-weight-600);
    padding: 8px 24px 8px 15px;
    bottom: 16px;
  }
}

.icon {
  color: $light-hex-100;

  @include screen(smallPhone) {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
  }
  @include screen(phone) {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
  }
  @include screen(tablet) {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
  }
  @include screen(tabletLandscape) {
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
  }
  @include screen(desktop) {
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
  }
  @include screen(desktopWide) {
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
  }
}

.label {
  padding-left: $space-s;
  // @include screen(smallPhone) {
  //   display: none;
  // }
  // @include screen(phone) {
  //   display: none;
  // }
  // @include screen(tablet) {
  //   display: none;
  // }
  // @include screen(tabletLandscape) {
  //   display: inline-block;
  // }
  // @include screen(desktop) {
  //   display: inline-block;
  // }
  // @include screen(desktopWide) {
  //   display: inline-block;
  // }
}
