@import "src/tokens";

.root {
}

.alternative {
  padding-top: $space-ml;
  display: flex;
  align-items: baseline;
  column-gap: $space-xs;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.loginForm {
  width: 100%;
}
