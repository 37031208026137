/*
  Fontstack
*/

$font-primary: "IBM Plex Sans", "SF Pro Display", -apple-system,
  BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell,
  "Helvetica Neue", sans-serif;
$font-secondary: "IBM Plex Sans", "SF Pro Display", -apple-system,
  BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell,
  "Helvetica Neue", sans-serif;
$font-monospace: "Roboto Mono", monospace;
$font-display: "IBM Plex Sans";
