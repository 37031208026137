@import "src/tokens";

.root {
}

.trigger {
  cursor: pointer;
  display: block;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: $radius-rounded;
  color: $dark-hex-70;
  border: 2px solid $dark-alpha-10;
  padding: 6px;
  transition: all 0.36s ease-out;

  &:hover {
    background-color: $dark-alpha-10;
    transition: all 0.28s ease-out;
  }

  &.marked {
    position: relative;
    // border-color: $brand-primary-100;
    // background-color: $brand-primary-10;

    &::after {
      content: " ";
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: $brand-primary-200;
      border-radius: $radius-rounded;
      top: 10px;
      left: 56%;
      z-index: 1;
      box-shadow: 0px 0px 0px 2px $light-hex-100;
    }
  }
}

.filled,
.none {
  display: block;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
}

.area {
  max-width: 375px;
  min-width: 288px;
  background-color: $bg-primary;
  margin-top: 4px;
  border-radius: 8px;
  width: 100%;
  box-shadow: 0px 2px 12px 2px $dark-alpha-20, 0px 8px 28px rgba(0, 0, 0, 0.28);
}

.header {
  padding: $space-m;
}

.list {
  width: 100%;
  padding-right: $space-m;
  padding-left: $space-m;
  padding-bottom: $space-ml;
  overflow-y: auto;
  max-height: calc(var(--viewport-height) - 144px);
}
