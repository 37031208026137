@import "src/tokens";

.root {
  width: 100%;

  &:not(:last-child) {
    margin-bottom: $space-field;
  }
}

.label {
  display: flex;
  align-items: baseline;
}

.field {
  padding: 11px;
  flex: 1;
  font-size: $heading-5;
  line-height: 24px;
  border-radius: $radius-field;
  display: flex;
  width: 100%;
  outline: none;
  box-sizing: border-box;
  appearance: none;
  color: $dark-alpha-80;
  background-color: $light-alpha-100;

  @include fieldInput();

  &.error {
    border-color: $brand-primary-200;
    background-color: $brand-primary-10;
  }
}

.hint {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $tone-danger-200;
  padding-top: $space-s;
  font-size: 14px;
  line-height: 16px;
  font-weight: $medium;
}
