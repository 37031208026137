@import "src/tokens";

.root {
}

.navbar {
  display: flex;
  flex: auto;
  padding-left: var(--tenka-space-ml);
  padding-right: var(--tenka-space-ml);
  padding-top: var(--tenka-space-s);
  padding-bottom: var(--tenka-space-ml);
  // border-bottom: 1px solid $dark-alpha-10;
  justify-content: space-between;
  align-items: center;
  column-gap: var(--tenka-space-sm);
}

.header {
  flex: 1;
  padding-right: 40px;
  text-align: center;
}

.main {
  flex: 1;
  overflow-y: auto;
  padding: var(--tenka-space-sm) var(--tenka-space-ml) var(--tenka-space-ml);
}

.footer {
  flex: auto;
  padding: var(--tenka-space-ml);
}

.close {
  cursor: pointer;
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  border-radius: var(--tenka-radius-rounded);
  padding: 6px;
  outline: none;
  color: var(--tenka-dark-hex-60);
  background-color: var(--tenka-light-60);
  transition: all 0.4s ease-out;
  margin-left: -8px;

  &:hover {
    color: var(--dark-hex-80);
    background-color: var(--tenka-dark-20);
    transition: all 0.28s ease-out;
  }
}

.icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
}
